/*!

=========================================================
* Material Kit PRO React - v1.7.0 based on Material Kit PRO v2.0.2 (Bootstrap 4.0.0 Final Edition)
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle; }

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr; }

.fileinput {
  text-align: center;
  display: inline-block;
  margin-bottom: 9px; }
  .fileinput input[type="file"] {
    display: none; }

.fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text; }

.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  max-width: 360px;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
  .fileinput .thumbnail.img-circle {
    border-radius: 50%;
    max-width: 100px; }

.fileinput .thumbnail > img {
  max-height: 100%;
  width: 100%; }

.fileinput .thumbnail.img-circle > img {
  height: 100px;
  width: 100px;
  max-height: none;
  object-fit: cover; }

.fileinput .btn {
  vertical-align: middle; }

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none; }

.fileinput-inline .fileinput-controls {
  display: inline; }

.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle; }

.form-control .fileinput-filename {
  vertical-align: bottom; }

.fileinput.input-group {
  display: table; }

.fileinput.input-group > * {
  position: relative;
  z-index: 2; }

.fileinput.input-group > .btn-file {
  z-index: 1; }

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0; }

.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0; }

.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0; }

.form-group.has-warning .fileinput .fileinput-preview {
  color: #ff9800; }

.form-group.has-warning .fileinput .thumbnail {
  border-color: #ff9800; }

.form-group.has-error .fileinput .fileinput-preview {
  color: #f44336; }

.form-group.has-error .fileinput .thumbnail {
  border-color: #f44336; }

.form-group.has-success .fileinput .fileinput-preview {
  color: #4caf50; }

.form-group.has-success .fileinput .thumbnail {
  border-color: #4caf50; }

.input-group-addon:not(:first-child) {
  border-left: 0; }

.thumbnail {
  border: 0 none;
  border-radius: 0;
  padding: 0; }

.picture-container {
  position: relative;
  cursor: pointer;
  text-align: center; }
  .picture-container.picture-container-error .picture {
    border-color: #f44336; }
  .picture-container.picture-container-error .description {
    color: #f44336; }
  .picture-container.picture-container-success .picture {
    border-color: #4caf50; }
  .picture-container.picture-container-success .description {
    color: #4caf50; }
  .picture-container .picture {
    width: 106px;
    height: 106px;
    background-color: #999999;
    border: 4px solid #cccccc;
    color: #ffffff;
    border-radius: 50%;
    margin: 5px auto;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    position: relative; }
    .picture-container .picture.picture_loading:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.35);
      position: absolute;
      width: 100%;
      height: 100%; }
    .picture-container .picture svg {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      margin: auto;
      animation: customSpin 1.3s infinite linear;
      display: none; }
    .picture-container .picture.picture_loading svg {
      display: block; }
    .picture-container .picture input[type="file"] {
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0 !important;
      position: absolute;
      top: 0;
      width: 100%; }
  .picture-container .picture-src {
    width: 100%;
    object-fit: cover;
    height: 100%; }

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  letter-spacing: normal !important; }

body {
  font-size: 1rem; }

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham-Book", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit; }

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em; }

h2 {
  font-size: 2.25rem;
  line-height: 1.5em; }

h3 {
  font-size: 1.5625rem;
  line-height: 1.4em; }

h4 {
  font-size: 1.125rem;
  line-height: 1.5em; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.55em; }

h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500; }

p {
  font-size: 14px;
  margin: 0 0 10px; }

b,
strong {
  font-weight: 700; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  background-color: #eee;
  color: #3c4858;
  margin: 0;
  font-size: 1rem;
  text-align: left; }

legend {
  border-bottom: 0; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box; }
  *:focus {
    outline: 0; }

a {
  color: #9c27b0;
  text-decoration: none;
  background-color: transparent; }
  a:hover, a:focus {
    color: #89229b;
    text-decoration: none; }

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400; }

small {
  font-size: 75%;
  color: #777;
  font-weight: 400; }

img {
  vertical-align: middle;
  border-style: none; }

form {
  margin-bottom: 1.125rem; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

#images h4 {
  margin-bottom: 30px; }

#root {
  overflow: hidden; }

#cd-vertical-nav {
  position: fixed;
  right: -78px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 4; }

#cd-vertical-nav ul {
  list-style: none;
  padding: 0; }

#cd-vertical-nav li {
  text-align: right; }

#cd-vertical-nav a {
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%; }

#cd-vertical-nav a:hover span,
#cd-vertical-nav a.is-selected .cd-dot {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

#cd-vertical-nav .cd-dot {
  position: relative;
  top: 8px;
  right: calc(100% - 15px);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #995581;
  -webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
  -moz-transition: -moz-transform 0.2s, background-color 0.5s;
  transition: transform 0.2s, background-color 0.5s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

#cd-vertical-nav a span {
  float: right;
  display: inline-block;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6); }

#cd-vertical-nav .cd-label {
  position: relative;
  margin-right: 10px;
  padding: 4px 14px;
  color: white;
  background: rgba(0, 0, 0, 0.53);
  font-size: 10px;
  border-radius: 20px;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%; }

#cd-vertical-nav a:after {
  content: "";
  display: table;
  clear: both; }

#cd-vertical-nav a:hover .cd-label {
  opacity: 1;
  left: -100%;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s; }

/*!
* https://github.com/YouCanBookMe/react-datetime
*/
.rdt {
  position: relative; }
  .rdt .rdtPicker {
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }
  .rdt input.form-control {
    border: 0;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.42857;
    display: block;
    width: 100%;
    color: #555; }
  .rdt input.form-control:focus {
    outline: none;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s; }

.rdtPicker {
  display: none;
  position: absolute;
  width: 260px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent; }
  .rdtPicker:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important;
  border-collapse: collapse; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  height: 30px;
  line-height: 33px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
    background-color: #9c27b0 !important;
    color: #fff;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6); }

.rdtDays tr .dow {
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px; }

.rdtDays tr .rdtOld,
.rdtDays tr .rdtNew {
  color: #bdbdbd; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

.rdtPicker th.rdtSwitch {
  width: 50px;
  padding: 5px;
  border-radius: 4px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
  border-radius: 50%;
  line-height: 33px; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #495057; }

.rdtPicker .rdtTimeToggle {
  color: #9c27b0; }

.rdtPicker .rdtTime th.rdtSwitch {
  color: #9c27b0; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #9c27b0;
  border-radius: 50%;
  border: 1px solid #9c27b0; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #9c27b0;
  -webkit-transition: all 60ms ease-in;
  -moz-transition: all 60ms ease-in;
  -o-transition: all 60ms ease-in;
  -ms-transition: all 60ms ease-in;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive,
    .rdtMonths .rdtYear.rdtActive,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtYear.rdtActive {
      background-color: #9c27b0 !important;
      color: #fff; }

.react-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px; }

.react-tagsinput-tag {
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  padding: 3px 8px;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.75em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.5em;
  padding-left: 0.8em; }

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold; }

.react-tagsinput-tag a::before {
  font-family: Font Awesome\ 5 Free;
  content: "\f00d";
  padding: 0px 2px;
  font-weight: 900; }

.react-tagsinput-tag a {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0px;
  opacity: 0;
  background-color: transparent;
  color: #ffffff; }

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px; }

.react-tagsinput .react-tagsinput-tag {
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
  background-color: #999; }
  .react-tagsinput .react-tagsinput-tag:hover {
    padding-right: 22px; }
    .react-tagsinput .react-tagsinput-tag:hover a {
      opacity: 1;
      padding-right: 4px;
      background-color: transparent;
      color: #ffffff; }
  .react-tagsinput .react-tagsinput-tag.primary {
    background-color: #9c27b0; }
  .react-tagsinput .react-tagsinput-tag.info {
    background-color: #00bcd4; }
  .react-tagsinput .react-tagsinput-tag.success {
    background-color: #4caf50; }
  .react-tagsinput .react-tagsinput-tag.warning {
    background-color: #ff9800; }
  .react-tagsinput .react-tagsinput-tag.danger {
    background-color: #f44336; }
  .react-tagsinput .react-tagsinput-tag.rose {
    background-color: #e91e63; }
  .react-tagsinput .react-tagsinput-tag.default {
    background-color: #999; }
