@font-face {
    font-family: 'Gotham-Medium';
    font-display: swap;
    src: url('./Gotham-Medium/Gotham-Medium.eot');
    src: url('./Gotham-Medium/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Gotham-Medium/Gotham-Medium.woff2') format('woff2'),
        url('./Gotham-Medium/Gotham-Medium.woff') format('woff'),
        url('./Gotham-Medium/Gotham-Medium.ttf') format('truetype'),
        url('./Gotham-Medium/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Light';
    font-display: swap;
    src: url('./Gotham-Light/GothamLight.eot');
    src: url('./Gotham-Light/GothamLight.eot?#iefix') format('embedded-opentype'),
        url('./Gotham-Light/GothamLight.woff2') format('woff2'),
        url('./Gotham-Light/GothamLight.woff') format('woff'),
        url('./Gotham-Light/GothamLight.ttf') format('truetype'),
        url('./Gotham-Light/GothamLight.svg#GothamLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Book';
    font-display: swap;
    src: url('./Gotham-Book/Gotham-Book.eot');
    src: url('./Gotham-Book/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('./Gotham-Book/Gotham-Book.woff2') format('woff2'),
        url('./Gotham-Book/Gotham-Book.woff') format('woff'),
        url('./Gotham-Book/Gotham-Book.ttf') format('truetype'),
        url('./Gotham-Book/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: 500;
    font-style: normal;
}